html {
  height: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: FoxSansPro, sans-serif;
}

body.MenuOpened {
  position: fixed;
  width: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
#root {
  height: 100%;
}
