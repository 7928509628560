.AssetIconWrapper {
  position: relative;
}

.AssetIconWrapper .OperationTypeIcon {
  position: absolute;
  right: -3px;
  bottom: -2px;
  width: 18px;
  height: 18px;
  color: transparent;
  cursor: default;
}
